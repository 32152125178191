import { Injectable } from '@angular/core';
import { assign, cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';

import { properties as propertiesData, categories as categoriesData } from './data';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PropertiesMockApi {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _properties: any = propertiesData;
  private _categories: any = categoriesData;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ List properties / List categories - GET
    // -----------------------------------------------------------------------------------------------------

    this._fuseMockApiService
      .onGet(environment.api.account + '/propertiesService', 2000)
      .reply(({ request }) => {
        // Clone the elements
        const data: any[] | null = cloneDeep(this._properties);

        // Prepare the pagination object
        const pagination = {};

        // Return the response
        return [200, { data, pagination }];
      });

    this._fuseMockApiService
      .onGet(environment.api.account + '/categoriesService', 2000)
      .reply(({ request }) => {
        // Clone the elements
        const data: any[] | null = cloneDeep(this._categories);

        // Prepare the pagination object
        const pagination = {};

        // Return the response
        return [200, { data, pagination }];
      });

    // -----------------------------------------------------------------------------------------------------
    // @ Read property / Read category - GET
    // -----------------------------------------------------------------------------------------------------

   /* this._fuseMockApiService
      .onGet(environment.api.url + '/modules/property', 2000)
      .reply(({ request }) => {
        // Get the ID and type from the query params
        const id = request.params.get('id');
        const type = request.params.get('type');

        // Prepare the elements
        let elements;

        // Clone the elements
        if (type === 'property') {
          elements = cloneDeep(this._properties);
        } else {
          elements = cloneDeep(this._categories);
        }

        // Find the element
        const element = elements.find((item) => item.id === id);

        // Return the response
        return [200, element];
      });*/

    // -----------------------------------------------------------------------------------------------------
    // @ Create property / Create category - POST
    // -----------------------------------------------------------------------------------------------------

    /*this._fuseMockApiService
      .onPost(environment.api.url + '/modules/property', 2000)
      .reply(({ request }) => {
        // Get the new element from the body
        const newElement: any = cloneDeep(request.body);

        // Get the type from the query params
        const type = request.params.get('type');

        // Set the type to the new element
        newElement.type = type;

        if (type === 'property') {
          // Generate the ID
          newElement.id = 19;

          // Unshift the new element
          this._properties.unshift(newElement);
        } else {
          // Generate the ID
          newElement.id = 4;

          // Unshift the new element
          this._categories.unshift(newElement);
        }

        // Return the response
        return [200, newElement];
      });*/

    // -----------------------------------------------------------------------------------------------------
    // @ Update property / Update category - PUT
    // -----------------------------------------------------------------------------------------------------

    /*this._fuseMockApiService
      .onPut(environment.api.url + '/modules/property', 2000)
      .reply(({ request }) => {
        // Get the ID and element from the body
        const id = request.body.id;
        const element = cloneDeep(request.body.element);

        // Get the type from the query params
        const type = request.params.get('type');

        // Prepare the updated element
        let updatedElement = null;

        // Find the element and update it
        if (type === 'property') {
          this._properties.forEach((item, index, elements) => {
            if (item.id === id) {
              // Update the element
              elements[index] = assign({}, elements[index], element);

              // Store the updated element
              updatedElement = elements[index];
            }
          });
        } else {
          this._categories.forEach((item, index, elements) => {
            if (item.id === id) {
              // Update the element
              elements[index] = assign({}, elements[index], element);

              // Store the updated element
              updatedElement = elements[index];
            }
          });
        }

        // Set the type to the updated element
        updatedElement.type = type;

        // Return the response
        return [200, updatedElement];
      });*/

    // -----------------------------------------------------------------------------------------------------
    // @ Delete property / Delete category - DELETE
    // -----------------------------------------------------------------------------------------------------

    /*this._fuseMockApiService
      .onDelete(environment.api.url + '/modules/property', 2000)
      .reply(({ request }) => {
        // Get the ID and type from the query params
        const id = request.params.get('id');
        const type = request.params.get('type');

        // Find the element and delete it
        if (type === 'property') {
          this._properties.forEach((item, index) => {
            if (item.id === id) {
              this._properties.splice(index, 1);
            }
          });
        } else {
          this._categories.forEach((item, index) => {
            if (item.id === id) {
              this._categories.splice(index, 1);
            }
          });
        }

        // Return the response
        return [200, true];
      });*/
  }
}
