<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-gray-900 print:hidden"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navigation"
  [opened]="!isScreenSmall"
>

  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationContentHeader>

    <!-- Logo -->
    <div class="flex flex-row items-center p-6">
      <img class="w-10 flex mr-3" alt="Logo image" [src]="lookAndFeel.menuLogo"/>
      <div class="text-3xl font-semibold leading-none">{{lookAndFeel.shortName}}</div>
    </div>
  </ng-container>

  <!-- Navigation footer hook -->
  <ng-container fuseVerticalNavigationContentFooter>
    <div class="flex flex-auto flex-row items-center justify-center gap-2">
      <a href="https://www.software-colombia.com/" target="_blank">
        <img class="w-8" alt="Software Colombia logo image" src="assets/images/logo/sc-logo.png"/>
      </a>
      <div class="flex flex-0 flex-col items-center justify-center h-16 text-md opacity-50">
        <div>Powered by
          <a class="font-semibold" href="https://www.software-colombia.com/" target="_blank">Software Colombia</a>
        </div>
        <div class="text-sm">{{currentVersion.full}}</div>
      </div>
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

  <!-- Header -->
  <div class="relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49 shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden">

    <!-- Navigation toggle button -->
    <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon svgIcon="menu"></mat-icon>
    </button>

    <!-- Components -->
    <div class="flex items-center pl-2 ml-auto space-x-1 sm:space-x-2">
      <languages></languages>
      <fuse-fullscreen class="hidden md:block" tooltip="Modo pantalla completa"></fuse-fullscreen>
      <settings></settings>
      <notifications></notifications>
      <user></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-col flex-auto">
    <router-outlet *ngIf="true"></router-outlet>
  </div>
</div>
