import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from 'environments/environment';
import { AccountService } from 'app/core/account';

@Injectable({
  providedIn: 'root',
})
export class MailDetailsService {

  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _accountService: AccountService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getMailsDetails(ids: string[]): Observable<any> {
    return this._accountService.account$
    .pipe(
      switchMap((account) => forkJoin(
          ids.map((id) =>
            this._httpClient
              .get<any>(
                `${environment.api.flow}/getNotificationReportService`,
                {
                  params: { uuid: id, accountUuid: account.accountUuid },
                }
              )
              .pipe(map((item) => item.reports[0]))
          )
        ))
    );
  }
}
