export class StringUtils {
  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Remove the blank spaces
   */
  public static removeBlankSpaces(sentence?: string): string {
    if (sentence) {
      return sentence.replace(/ +(?= )/g, '').trim();
    } else {
      return '';
    }
  }

  /**
   * Remove the blank spaces
   */
  public static removeAllBlankSpaces(sentence?: string): string {
    if (sentence) {
      return sentence.replace(/\s/g, '').trim();
    } else {
      return '';
    }
  }

  /**
   * Capitalize the first letter
   */
  public static capitalizeFirstLetter(sentence?: string): string {
    if (sentence) {
      sentence = this.removeBlankSpaces(sentence);
      return sentence[0].toUpperCase() + sentence.substring(1).toLowerCase();
    } else {
      return '';
    }
  }

  /**
   * Capitalize the first letter of every word
   */
  public static capitalizeFirstLetters(sentence?: string): string {
    if (sentence) {
      sentence = this.removeBlankSpaces(sentence);
      const words: string[] = sentence.toLowerCase().split(' ');

      return words
        .map((word) => {
          if (word !== null && word !== '') {
            return word[0].toUpperCase() + word.substring(1).toLowerCase();
          } else {
            return null;
          }
        })
        .join(' ');
    } else {
      return '';
    }
  }
}
