export interface LookAndFeel {
  name?: string;
  shortName?: string;
  homeDescription?: string;
  authDescription?: string;
  homeLogo?: string;
  authLogo?: string;
  menuLogo?: string;
  notificationLogo?: string;
  color?: string;
  subdomain?: string;
  hexa?: string;
  root?: boolean;
  email?: string;
  authorized?: boolean;
  senderName?: string;
  login?: Login;
}

export interface Login {
  type?: LoginType;
  port?: string;
  endPoint?: string;
  clientId?: string;
  authority?: string;
  scopes?: string;
}
export enum LoginType {
  USER_PASSWORD = 'USER_PASSWORD',
  MICROSOFT_OAUTH_2_0 = 'MICROSOFT_OAUTH_2_0',
  MICROSOFT_USER_PASSWORD = 'MICROSOFT_USER_PASSWORD',
  LEGACY_AD = 'LEGACY_AD',
}
