<div class="mail flex flex-col max-w-240 md:min-w-140 -m-6">
  <div class="flex flex-col bg-gray-200 dark:bg-gray-700">
    <div class="bg-white dark:bg-gray-900 relative drop-shadow-2xl rounded-2xl p-4 m-3">
      <div class="flex-none sm:flex">
        <div class="flex-auto justify-evenly">

          <!-- Header -->
          <div class="flex items-center justify-between">
            <div class="font-bold text-lg text-primary-800 dark:text-primary-500">Detalle de notificaciones</div>
            <button mat-icon-button type="button" (click)="matDialogRef.close()">
              <mat-icon class="text-current" svgIcon="close"></mat-icon>
            </button>
          </div>

          <!-- Divider -->
          <div class="border-b border-dashed border-b-2 my-5"></div>

          <ng-container *ngIf="!isLoading">
            <ng-container *ngIf="notifications?.length; else noMails">

              <ng-container *ngFor="let notification of details; let index = index; let first = first; let last = last">

                <!-- Circles -->
                <ng-container *ngIf="!first">
                  <div class="border-b border-dashed border-b-2 my-5 pt-5">
                    <div
                      class="absolute rounded-full w-5 h-5 bg-gray-200 dark:bg-gray-700 -mt-2 -left-2"
                    ></div>
                    <div
                      class="absolute rounded-full w-5 h-5 bg-gray-200 dark:bg-gray-700 -mt-2 -right-2"
                    ></div>
                  </div>
                </ng-container>

                <!-- Main mail section -->
                <div class="flex flex-col md:flex-row mb-3 md:mt-0">

                  <div class="flex flex-col border-b md:border-b-0 md:border-r md:pr-4">
                    <div
                      class="w-full flex-none text-md text-primary-800 dark:text-primary-500 font-bold leading-none"
                    >
                      <span *ngIf="first">Notificación principal</span>
                      <span *ngIf="!first">Recordatorio {{' ' + index}}</span>
                    </div>

                    <div class="mt-3">
                      <div class="flex-auto text-sm my-1">
                        <span class="text-gray-500 mr-1">Estado:</span>
                        <ng-container *ngIf="notification?.emailStatus === states.DELIVERED">
                          <span class="font-semibold" *ngIf="notification?.user?.clicksCount > 0">Click</span>
                          <span class="font-semibold" *ngIf="notification?.user?.openingsCount > 0 && notification?.user?.clicksCount < 1">Abierto</span>
                          <span class="font-semibold" *ngIf="notification?.user?.openingsCount < 1 && notification?.user?.clicksCount < 1">Entregado</span>
                        </ng-container>
                        <ng-container *ngIf="notification?.emailStatus !== states.DELIVERED">
                          <span class="font-semibold">{{ translator.translate(notification.emailStatus) }}</span>
                        </ng-container>
                      </div>

                      <div class="flex-auto text-sm my-1">
                        <span class="text-gray-500 mr-1">Receptor:</span>
                        <span class="font-semibold truncate">
                          {{ (notification?.emailAddress?.length > 37) ? (notification?.emailAddress | slice:0:37)+'...':(notification?.emailAddress) }}
                        </span>
                      </div>

                      <div class="flex-auto text-sm my-1">
                        <span class="text-gray-500 mr-1">Hora de recepción:</span>
                        <span class="font-semibold">{{ (notification.delivery.reception * 1000 | date: 'MMMM ' | titlecase) + (notification.delivery.reception * 1000 | date: 'd, y, h:mm a') }}</span>
                      </div>
                    </div>

                  </div>

                  <div class="flex flex-col mx-auto mt-3 md:mt-0 justify-center md:justify-start items-center md:items-start">
                    <div class="flex text-lg text-primary font-bold leading-none">
                      {{ notification.user.openingsCount ? notification.user.openingsCount : 0 }}
                    </div>
                    <div class="text-sm">Aperturas</div>

                    <ng-container *ngIf="notification?.user?.openingsCount">
                      <div class="flex pt-5 text-sm">
                         <div class="flex items-center md:items-start flex-col">
                           <span class="text-sm">Primer apertura</span>
                           <div class="font-semibold">{{ (notification.user.firstOpeningTime * 1000 | date: 'yyyy/MM/dd,') }}</div>
                           <div class="font-semibold">{{ (notification.user.firstOpeningTime * 1000 | date: 'h:mm a') }}</div>
                         </div>
                      </div>
                     </ng-container>
                  </div>

                  <div class="flex flex-col mx-auto border-t-2 md:border-t-0 p-3 md:p-0 mt-3 md:mt-0 justify-center md:justify-start items-center md:items-start">
                    <div class="flex-none text-lg text-primary font-bold leading-none">
                      {{ notification?.user?.clicksCount ? notification?.user?.clicksCount : 0}}
                    </div>
                    <div class="text-sm">Clicks</div>

                    <ng-container *ngIf="notification?.user?.clicksCount">
                      <div class="flex pt-5 text-sm">
                        <div class="flex items-center md:items-start flex-col">
                          <span class="text-sm">Primer click</span>
                          <div class="font-semibold">{{ (notification.user.firstClickTime * 1000 | date: 'yyyy/MM/dd,') }}</div>
                          <div class="font-semibold">{{ (notification.user.firstClickTime * 1000 | date: 'h:mm a') }}</div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>

                <div class="flex-auto text-sm my-1">
                  <!-- Internal link -->
                  <a class="cursor-pointer text-primary-600 hover:text-primary-500" (click)="downloadFile(notification.reportUrl)">
                    Reporte detallado
                  </a>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <!-- Loader -->
          <ng-container *ngIf="isLoading">
            <div class="flex flex-auto items-center justify-center p-8 sm:p-10">
              <mat-progress-spinner color="primary" mode="indeterminate" [diameter]="80"></mat-progress-spinner>
            </div>
          </ng-container>

          <!-- Powered by -->
          <div class="flex flex-col py-5 justify-center text-sm">
            <div class="flex items-center my-1 justify-center">
                    <span class="mr-3 w-8 h-9">
                      <img
                        src="https://en.software-colombia.com/wp-content/uploads/2021/10/Scmail.png"
                        class="h-9 p-1"
                        alt="Software Colombia SCmail logo"
                      />
                    </span>
              <h2 class="font-medium">
                <span class="text-secondary text-xs mr-1">Powered by</span>
                <a class="hover:text-primary" href="https://en.software-colombia.com/services/certified-mail/" target="_blank">SCMail</a>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<ng-template #noMails>
  <div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">
    <div class="flex flex-auto flex-col items-center justify-center dark:bg-transparent">
      <mat-icon class="icon-size-16" svgIcon="query_stats"></mat-icon>
      <div class="mt-4 text-lg tracking-tight text-secondary">
        ¡El usuario no cuenta con reportes aún!
      </div>
    </div>
  </div>
</ng-template>
