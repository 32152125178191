/* eslint-disable curly */
/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';

import { SnackbarService } from 'app/core/snackbar';
import { HClientService } from 'app/shared/h-client/h-client.service';
import { HClientErrors } from 'app/shared/h-client/h-client.constants';

@Component({
  selector: 'h-client',
  templateUrl: './h-client.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class HClientComponent implements OnInit, OnDestroy {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  isLoading = true;
  isAvailable = false;

  options = { path: '/assets/lottie/verifyFingerprint.json' };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _matDialogRef: MatDialogRef<HClientComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _snackbar: SnackbarService,
    private _hClientService: HClientService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    if (this.data && this.data.signer && this.data.account) this.startHClient();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  startHClient(): void {
    this.isLoading = true;
    this._changeDetectorRef.markForCheck();

    this._hClientService.check().pipe(
      takeUntil(this._unsubscribeAll),
      switchMap((isAvailable) => {
        if (isAvailable) return this._validate();
        this.isLoading = false;
        this._changeDetectorRef.markForCheck();
        return of(null);
      })
    ).subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  private _validate(): Observable<any> {
    return this._hClientService.encrypt(this.data.signer, this.data.account, this.data.document)
      .pipe(
        takeUntil(this._unsubscribeAll),
        switchMap((body) => {
          this.isLoading = false;
          this.isAvailable = true;
          this._changeDetectorRef.markForCheck();

          return this._hClientService.validate(body).pipe(
            switchMap((result) => {
              if (result.status === 'CANCELLED')
                return this._onError(HClientErrors.canceled);

              if (result.status !== 'SUCCESS' || result.success !== 'true')
                return this._onError(HClientErrors.failed);

              const response = JSON.parse(result.response);
              if (response.resultado !== 'Autorizado')
                return this._onError(HClientErrors.failed);

              return this._onSuccess(response);
            })
          );
        })
      );
  }

  private _onSuccess(response: any): Observable<any> {
    this._matDialogRef.close(response);
    return of(null);
  }

  private _onError(message: string): Observable<any> {
    this._snackbar.showError(message);
    this._matDialogRef.close(null);
    return of(null);
  }
}
