import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from '@angular/router';
import { forkJoin, iif, Observable, of } from 'rxjs';

import { UserService } from 'app/core/user';
import { AccountService } from 'app/core/account';
import { LookAndFeelService } from 'app/modules/look-and-feel/look-and-feel.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import {map, mergeMap, tap} from 'rxjs/operators';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';
import {roles} from "./modules/users/users.constants";

@Injectable()
export class InitialDataResolver implements Resolve<any> {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _userService: UserService,
    private _accountService: AccountService,
    private _lookAndFeelService: LookAndFeelService,
    private _notificationsService: NotificationsService,
    private _splashService: FuseSplashScreenService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolve
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
      this._userService.get(),
      this._accountService.get(),
      this._accountService.roles(),
      this._lookAndFeelService.getLookAndFeel(),
      this._notificationsService.getNotifications(),
    ]);
  }
}

@Injectable()
export class LookAndFeelResolver implements Resolve<any> {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _lookAndFeelService: LookAndFeelService,
    private _splashService: FuseSplashScreenService,
    private _notificationsService: NotificationsService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resolve initial data for the application
   */
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    this._splashService.show();
    return this._lookAndFeelService.getLookAndFeel()
      .pipe(tap(() => this._splashService.hide()));
  }
}
