/* eslint-disable curly */
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseAlertType } from '@fuse/components/alert';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone/lib/ngx-dropzone/ngx-dropzone.component';
import { fuseAnimations } from '@fuse/animations';

import { DropZoneService } from './drop-zone.service';

@Component({
  selector: 'drop-zone',
  templateUrl: './drop-zone.component.html',
  styleUrls: ['./drop-zone.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class DropZoneComponent implements OnInit {

  // -----------------------------------------------------------------------------------------------------
  // @ Parameters
  // -----------------------------------------------------------------------------------------------------

  // Allows dropzone receive more than one file
  @Input() multiple: boolean = false;
  // View Mode or Edit and Create mode
  @Input() enable: boolean = true;
  // List of allowed file extensions
  @Input() accept: string = '*';
  // Max size allowed
  @Input() maxSize: number =  262144000;

  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  files: File[] = [];
  showDocAlert = false;
  docAlert: { type: FuseAlertType; message: string } = { type: 'error', message: '' };
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _dropZoneService: DropZoneService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this._initFiles();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
    this._dropZoneService.files = [...this.files];
  }

  onSelect(event: NgxDropzoneChangeEvent): void {
    this.showDocAlert = false;
    this.files.pop();
    if (event.addedFiles && event.addedFiles.length > 0) this._checkSize(event);
    else {
      this.docAlert = { type: 'error', message: 'El formato del archivo que seleccionaste es inválido' };
      this.showDocAlert = true;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  private _checkSize(event): void {
    const isAllowed = event.addedFiles.some((file) => file.size < this.maxSize);
    if (isAllowed) this._setData(event);
    else
      this.docAlert = {
        type: 'error',
        message: this.multiple ? 'Alguno de los archivos seleccionados son demasiado grandes' : 'El archivo que seleccionaste es demasiado grande',
      };
  }

  private _setData(event): void {
    if (this.multiple) this.files.push(...event.addedFiles);
    else this.files.push(event.addedFiles[0]);
    this._dropZoneService.files = [...this.files];
  }

  private _initFiles(): void {
    this._dropZoneService.files$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((files) => {
        if (files) {
          this.files.splice(0, this.files.length);
          this.files.push(...files);
        }

        this._changeDetectorRef.markForCheck();
      });
  }
}
