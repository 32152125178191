import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'spinner',
})
export class SpinnerComponent implements OnInit {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  @Input() color: 'primary' | 'dark' | 'white';

  className: string;

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the spinner color
    switch (this.color) {
      case 'primary':
        this.className = 'bg-orange-400';
        break;
      case 'dark':
        this.className = 'bg-dark';
        break;
      case 'white':
      default:
        this.className = 'bg-white';
        break;
    }
  }
}
