<!-- Notifications toggle -->
<button
  mat-icon-button
  [matTooltip]="'Panel de notificaciones'"
  [disabled]="isModule"
  (click)="openPanel()"
  #notificationsOrigin
>
  <ng-container *ngIf="notifications$ | async as notifications">
    <ng-container *ngIf="notifications.length && notificationType === notificationMode.toRead">
      <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
        <span class="flex items-center justify-center flex-shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-600 text-indigo-50 text-xs font-medium">
          {{ notifications.length }}
        </span>
      </span>
    </ng-container>
  </ng-container>

  <mat-icon [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>


  <!-- Notifications panel -->
  <ng-template #notificationsPanel>
    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">
      <!-- Header -->
      <div class="flex flex-shrink-0 items-center py-1 px-4 bg-white dark:bg-gray-700 border-l border-r border-t rounded-t-2xl">
        <div class="sm:hidden -ml-4 mr-3">
          <button mat-icon-button (click)="closePanel()">
            <mat-icon
              class="icon-size-5 text-current"
              [svgIcon]="'heroicons_solid:x'"
            ></mat-icon>
          </button>
        </div>
        <div class="text-lg font-medium leading-10">Notificaciones</div>
        <div class="ml-auto">
          <ng-container *ngIf="notifications$ | async as notifications">
            <button
              mat-icon-button
              [matTooltip]="'Marcar todo como leído'"
              [disabled]="notifications?.length < 1 || notificationType === notificationMode.read"
              (click)="readAll()"
            >
              <mat-icon
                class="icon-size-5 text-primary dark:text-primary-500"
                [svgIcon]="'heroicons_solid:mail-open'"
              ></mat-icon>
            </button>
          </ng-container>
        </div>
      </div>

      <!-- Type selector -->
      <div
        matRipple
        class="w-full relative flex self-start pt-2 pb-1 pl-5 pr-4 cursor-pointer overflow-hidden border border-b-0 bg-card dark:bg-default"
        [matMenuTriggerFor]="typesMenu">
        <div class="flex ml-auto items-center">
          <div class="overflow-hidden">
            <div class="font-medium leading-6 truncate">
              <span *ngIf="notificationType === notificationMode.toRead">Por leer</span>
              <span *ngIf="notificationType === notificationMode.read">Leídas</span>
            </div>
          </div>
          <div class="flex items-center justify-center pl-2">
            <mat-icon svgIcon="expand_more"></mat-icon>
          </div>
        </div>
        <mat-menu #typesMenu="matMenu" xPosition="before">
          <button mat-menu-item (click)="onTypeChanged(notificationMode.toRead)">Por leer</button>
          <button mat-menu-item (click)="onTypeChanged(notificationMode.read)">Leídas</button>
        </mat-menu>
      </div>

      <!-- Loader -->
      <ng-container *ngIf="isLoading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </ng-container>

      <!-- Content -->
      <div class="relative flex flex-col flex-auto sm:max-h-120 overflow-y-auto border-t border-l border-r bg-card">

        <!-- Notifications -->
        <ng-container *ngIf="notifications$ | async as notifications; else loading">
          <ng-container *ngFor="let notification of notifications; let last = last; trackBy: trackByFn">
            <div
              class="flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5"
              [ngClass]="{ unread: !notification.element.status }"
            >
              <!-- Actions -->
              <div class="relative flex flex-col my-5 ml-2 mr-1">
                <!-- Status Indicator -->
                <ng-container *ngIf="notification.element.status">
                  <button
                    class="w-6 h-6 min-h-6"
                    mat-icon-button
                    [matTooltip]="'Marcar como leído'"
                    (click)="read(notification)"
                  >
                    <span class="w-2 h-2 rounded-full bg-primary"></span>
                  </button>
                  </ng-container>
              </div>

              <a class="flex flex-auto py-5 pr-6" matTooltip="Ver detalles" (click)="details(notification)"
                [ngClass]="{
                  'cursor-pointer': notification.element.details
                }"
              >
                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
              </a>
            </div>

            <!-- Divider -->
            <mat-divider *ngIf="!last" class="mx-4"></mat-divider>

            <!-- Notification content template -->
            <ng-template #notificationContent>

              <!-- Title, description & time -->
              <div class="flex flex-col flex-auto">
                <ng-container *ngIf="notification.element.message">
                  <div class="line-clamp-2 text-md" [matTooltip]="notification.element.message">
                    {{notification.element.message}}
                  </div>
                </ng-container>

                <div class="mt-2 text-sm leading-none text-secondary">
                  {{ notification.element.generationDate | date: "MMM dd, h:mm a" | titlecase}}
                </div>

                <ng-container *ngIf="notification.element.readingDate">
                  <div class="mt-2 ml-auto text-sm leading-none text-secondary">
                    Leída: {{ notification.element.readingDate | date: "MMM dd, h:mm a" | titlecase}}
                  </div>
                </ng-container>
              </div>

              <!-- Icon -->
              <ng-container *ngIf="notification.element.icon">
                <div class="flex flex-col ml-8">
                  <div
                    class="flex flex-shrink-0 items-center justify-center w-10 h-10 rounded-full bg-primary dark:bg-primary-700"
                  >
                    <mat-icon
                      class="icon-size-6 text-white"
                      [svgIcon]="notification.element.icon"
                    ></mat-icon>
                  </div>
                </div>
              </ng-container>
            </ng-template>
          </ng-container>

          <!-- No notifications -->
          <ng-container *ngIf="!notifications || !notifications.length">
            <div class=" flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
              <div class=" flex flex-0 items-center justify-center w-14 h-14 rounded-full bg-primary-100 dark:bg-primary-500">
                <mat-icon class="text-primary-500-700 dark:text-primary-50" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
              </div>
              <div class="mt-5 text-2xl font-semibold tracking-tight">
                Sin notificaciones
              </div>
              <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">
                Cuando tengas notificaciones, aparecerán aquí.
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <!-- Footer -->
      <div class="text-primary bg-gray-100 dark:bg-gray-700 p-4 border rounded-b-2xl">
      </div>
    </div>
  </ng-template>



<ng-template #loading>
  <mat-progress-bar mode="query"></mat-progress-bar>
</ng-template>
