import { Injectable } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { FuseMockApiService } from '@fuse/lib/mock-api';

import { roles as rolesData } from './data';

@Injectable({
  providedIn: 'root',
})
export class RolesMockApi {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private readonly _roles = rolesData;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(private _fuseMockApiService: FuseMockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    // -----------------------------------------------------------------------------------------------------
    // @ Roles - GET
    // -----------------------------------------------------------------------------------------------------

    this._fuseMockApiService
      .onGet('api/common/roles')
      .reply(({ request }) =>
        // Return the response
         [200, cloneDeep(this._roles)]
      );
  }
}
