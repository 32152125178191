<div class="flex flex-col h-full w-full max-h-screen">

  <!-- Content -->
  <div class="flex flex-col flex-auto overflow-y-auto">

    <!-- eLogic Biometrics wrapper-->
    <div id="ebm" class="ebm-wrapper">

      <!-- Loader -->
      <ng-container *ngIf="isLoading">
        <div class="flex items-center justify-center h-full p-4 sm:p-6">
          <mat-progress-spinner 
            color="primary" mode="indeterminate" [diameter]="80">
          </mat-progress-spinner>
        </div>
      </ng-container>
    </div>
  </div>
</div>
