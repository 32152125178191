import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class EBiometricsTransactionsService {

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  downloadReport(id: string, documentUuid: string, order: any): Observable<string> {
    return this._httpClient.get<any>(
      `${environment.api.reportAndNotification}/biometricReport`,
      { params: { id, documentUuid, order } }
    )
    .pipe(map((response) => response.url));
  }
}
