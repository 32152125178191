import { IdType } from './users.types';

export enum roles {
  ADMINISTRADOR = 'ADMINISTRADOR',
  PROPIETARIO = 'PROPIETARIO',
  FIRMANTE = 'FIRMANTE',
  REPORTEADOR = 'REPORTEADOR',
  AUDITOR = 'AUDITOR',
}

export enum rolesWithSuperAdmin {
  ADMINISTRADOR = 'ADMINISTRADOR',
  PROPIETARIO = 'PROPIETARIO',
  FIRMANTE = 'FIRMANTE',
  REPORTEADOR = 'REPORTEADOR',
  AUDITOR = 'AUDITOR',
  SUPERADMINISTRADOR = 'SUPERADMINISTRADOR'
}


export const idTypes: IdType[] = [
  {
    code: 'CC',
    name: 'Cédula de ciudadanía',
    value: 'CEDULA_CIUDADANIA',
  },
  {
    code: 'NIT',
    name: 'Número de identificación tributaria',
    value: 'NIT',
  },
  {
    code: 'CE',
    name: 'Cédula de extranjería',
    value: 'CEDULA_EXTRANJERIA',
  },
  {
    code: 'PA',
    name: 'Pasaporte',
    value: 'PASAPORTE',
  },
];
