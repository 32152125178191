import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, timer } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SnackbarService } from 'app/core/snackbar';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class OtpService {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _snackBar: SnackbarService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  getCounter(tick: number): Observable<number> {
    return timer(0, tick);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getOTP({ phone, email, id, ids }): Observable<{ otp: string }> {
    return this._httpClient
      .post<{ otp: string }>(`${environment.api.flow}/sendOTPService`, { phone, email, id, ids, source: 'eWorkFlow' })
      .pipe(catchError((error) => {
        this._snackBar.showError(error.error.message);
        return of (error);
      }));
  }
}
