import { NavigationMockApi } from './common/navigation/api';
import { CountriesMockApi } from './common/countries/api';
import { RolesMockApi } from './common/roles/api';

import { DocumentsMockApi } from './modules/documents/api';
import { SignMockApi } from './modules/sign/api';
import { PropertiesMockApi } from './modules/properties/api';

export const mockApiServices = [
  // Common
  NavigationMockApi,
  CountriesMockApi,
  RolesMockApi,

  // Modules
  DocumentsMockApi,
  SignMockApi,
  PropertiesMockApi,
];
