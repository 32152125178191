import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseAlertModule } from '@fuse/components/alert';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgOtpInputModule } from 'ng-otp-input';
import { LottieModule } from 'ngx-lottie';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { EmptyComponent } from './empty/empty.component';
import { FiltersBarComponent } from './filters-bar/filters-bar.component';
import { FiltersBarService } from './filters-bar/filters-bar.service';
import { FlowService } from './flow/flow.service';
import { PaginatorComponent } from './paginator/paginator.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { WebViewerComponent } from './webviewer/webviewer.component';
import { DropZoneComponent } from './drop-zone/drop-zone.component';
import { OtpComponent } from './otp/otp.component';
import { ConfirmationModule } from './confirmation/confirmation.module';
import { MailDetailsComponent } from './mail-details/mail-details.component';
import { EBiometricsComponent } from './e-biometrics/e-biometrics.component';
import { EBiometricsTransactionsComponent } from './e-biometrics-transactions/e-biometrics-transactions.component';
import { HClientComponent } from './h-client/h-client.component';
import { FingerprintComponent } from './h-client/fingerprint/fingerprint.component';
import { SafePipe } from '../core/utils/pipes/safe.pipe';
import { FilesizePipe } from 'app/core/utils/pipes/filesize.pipe';

@NgModule({
  declarations: [
    EmptyComponent,
    FiltersBarComponent,
    PaginatorComponent,
    SpinnerComponent,
    WebViewerComponent,
    DropZoneComponent,
    OtpComponent,
    MailDetailsComponent,
    EBiometricsComponent,
    EBiometricsTransactionsComponent,
    HClientComponent,
    FingerprintComponent,
    SafePipe,
    FilesizePipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    MatTooltipModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    FuseAlertModule,
    NgxDropzoneModule,
    NgOtpInputModule,
    MatDialogModule,
    NgxExtendedPdfViewerModule,
    LottieModule,
  ],
  providers: [FiltersBarService, FlowService],
  exports: [
    CommonModule,
    FormsModule,
    ConfirmationModule,
    ReactiveFormsModule,
    EmptyComponent,
    FiltersBarComponent,
    PaginatorComponent,
    SpinnerComponent,
    WebViewerComponent,
    DropZoneComponent,
    EBiometricsComponent,
    EBiometricsTransactionsComponent,
    HClientComponent,
    SafePipe,
    FilesizePipe,
  ],
})
export class SharedModule {}
