<!-- Trigger button -->
<button
  mat-icon-button
  [matMenuTriggerFor]="userActions" [matTooltip]="tooltip || 'Opciones de usuario'">
  <span class="relative">
    <mat-icon svgIcon="account_circle"></mat-icon>
    <span class="absolute mr-px mb-px right-0 bottom-0 w-2 h-2 rounded-full"></span>
  </span>
</button>

<!-- Main menu -->
<mat-menu xPosition="before" #userActions="matMenu">

  <!-- Account info -->
  <div class="flex flex-col leading-none p-3">

    <!-- Parent account -->
    <ng-container *ngIf="parent && parent.company.name">
      <span class="font-bold">
        {{parent.company.name}}
      </span>
    </ng-container>

    <!-- Selected account -->
    <ng-container *ngIf="account && account.name">
      <span class="mt-1.5 text-xl font-bold">
        {{account.name}}
      </span>
    </ng-container>
  </div>

  <!-- Divider-->
  <mat-divider class="my-2"></mat-divider>

  <!-- Account info -->
  <div class="flex flex-col leading-none p-3">

    <!-- Email -->
    <ng-container *ngIf="user && user.attributes && user.attributes.email">
      <span class="text-md font-medium">
        {{user.attributes.email}}
      </span>
    </ng-container>

    <!-- Selected role -->
    <ng-container *ngIf="account && account.selectedRole">
      <span class="mt-1.5 font-medium">
        {{account.selectedRole | titlecase}}
      </span>
    </ng-container>
  </div>

  <!-- Divider-->
  <mat-divider class="my-2"></mat-divider>

  <!-- Change role -->
  <ng-container *ngIf="account && account.roles">
    <button mat-menu-item [matMenuTriggerFor]="userRoles">
      <mat-icon svgIcon="people"></mat-icon>
      <span>Cambiar de rol</span>
    </button>

    <!-- Roles menu -->
    <mat-menu class="user-status-menu" #userRoles="matMenu">
      <ng-container *ngFor="let role of account.roles">
        <button mat-menu-item (click)="changeRole(role)">
          <mat-icon class="icon-size-5" [svgIcon]="getRoleByValue(role).icon"></mat-icon>
          <span>{{getRoleByValue(role).label}}</span>
        </button>
      </ng-container>
    </mat-menu>
  </ng-container>

  <!-- Change account -->
  <button mat-menu-item (click)="changeAccount()">
    <mat-icon svgIcon="account_tree"></mat-icon>
    <span>Cambiar de cuenta</span>
  </button>

  <!-- Change account -->
  <button mat-menu-item (click)="myOrganization()">
    <mat-icon svgIcon="business"></mat-icon>
    <span>Mi organización</span>
  </button>

  <!-- Support -->
  <a mat-menu-item target="_blank"
    href="https://software-colombia.atlassian.net/servicedesk/customer/portal/30">
    <mat-icon svgIcon="support_agent"></mat-icon>
    <span>Contacto y soporte</span>
  </a>

  <!-- Changelog -->
  <a mat-menu-item target="_blank" [routerLink]="['./public/changelog']">
    <mat-icon svgIcon="campaign"></mat-icon>
    <span>Registro de cambios</span>
  </a>

  <!-- Divider -->
  <mat-divider class="my-2"></mat-divider>

  <!-- Sign out button -->
  <button mat-menu-item (click)="signOut()">
    <mat-icon svgIcon="logout"></mat-icon>
    <span>Cerrar sesión</span>
  </button>
</mat-menu>
