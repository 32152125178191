/* eslint-disable quote-props */
/* eslint-disable @typescript-eslint/naming-convention */
export const dictionary: { [key: string]: string } = {
  state: 'Estado',

  // User roles
  ADMINISTRADOR: 'Administrador',
  PROPIETARIO: 'Propietario',
  FIRMANTE: 'Firmante',
  SUPERADMINISTRADOR: 'Superadministrador',
  REPORTEADOR: 'Reporteador',
  AUDITOR: 'Auditor',

  // Entities filters
  nit: 'NIT',
  creationDate: 'Fecha de creación',

  // User filters
  email: 'Correo electrónico',
  firstName: 'Nombres',
  lastName: 'Apellidos',
  status: 'Estado',

  // Report filters
  name: 'Nombre',
  logicAlias: 'Lógica de reporte',

  // Report states
  CREATED: 'Creado',
  PROCESSING: 'Procesando',
  GENERATED: 'Generado',

  // Audit filters
  MARK_AS_READ: 'Edición de notificación',
  AUTHENTICATION: 'Autenticación en la plataforma',
  CREATED_AUTOMATIC_REPORT: 'Creación de reporte automático',
  EDITED_AUTOMATIC_REPORT: 'Edición de reporte automático',
  DELETED_AUTOMATIC_REPORT: 'Eliminacion de reporte automático',
  EDITED_CALLBACK: 'Edición de interoperabilidad',
  CREATED_ENTITY: 'Creación de cuenta',
  EDITED_ENTITY: 'Edición de cuenta',
  DELETED_ENTITY: 'Eliminación de cue,nta',
  EDITED_LOOK_AND_FEEL: 'Edición de look and feel',
  CREATED_PLAN: 'Creación de plan',
  EDITED_PLAN: 'Edición de plan',
  INACTIVATED_PLAN: 'Inactivación de plan',
  ACTIVATED_PLAN: 'Activación de plan',
  CREATED_PROMISE_NOTE: 'Creación de pagaré',
  EDITED_PROMISE_NOTE: 'Endoso de pagaré',
  ARCHIVED_PROMISE_NOTE: 'Archivación de pagaré',
  EXECUTED_PROMISE_NOTE: 'Ejecución de pagaré',
  ENDORSE_PROMISE_NOTE: 'Endoso de pagaré',
  DELETED_PROMISE_NOTE: 'Cancelación de pagaré',
  CALLBACK_EXECUTION: 'Ejecución de callback',
  CREATED_FOLDER: 'Creación de expediente',
  EDITED_FOLDER: 'Edición de expediente',
  DELETED_FOLDER: 'Eliminación de expediente',
  CREATED_USER: 'Creación de usuario',
  EDITED_USER: 'Edición de usuario',
  DELETED_USER: 'Eliminación de usuario',
  INACTIVATED_USER: 'Inactivación de usuario',
  CREATED_WORKFLOW: 'Creación de flujo de firma',
  EDITED_WORKFLOW: 'Endoso de flujo de firma',
  ARCHIVED_WORKFLOW: 'Archivación de flujo de firma',

  // Report logics
  DocumentsReportLogic: 'Reporte de flujos',

  // Plan types
  NORMAL: 'Flujo de firma simple',
  PROMISE_NOTE: 'Pagaré electrónico',
  DIGITAL_CONTRACT: 'Contrato electrónico inteligente',

  // Plan validations
  OTP: 'OTP',
  H_CLIENT: 'Biométrica dactilar',
  E_BIOMETRICS: 'Biométrica facial',
  E_BIOMETRICS_IDEMIA: 'Biométrica facial (IDEMIA)',
  NONE: 'Ninguno',

  // Signature types
  CUSTOM: 'Firma personalizada',
  DESCRIPTION: 'Firma con descripción',
  NAME_AND_DESCRIPTION: 'Firma con nombre y descripción',
  GRAPHIC: 'Firma con gráfica',
  GRAPHIC_AND_DESCRIPTION: 'Firma con gráfica y descripción',
  INVISIBLE: 'Firma invisible',

  // My organization
  MyOrganization: 'Mi organización',

  // Document filters
  docEnd: 'Fecha de fin',
  docStart: 'Fecha de inicio',
  docName: 'Nombre de documento',
  docEmail: 'Correo del firmante',
  docId: 'Identificación del firmante',
  docPhone: 'Teléfono del firmante',
  docContentType: 'Tipo',

  // Workflow Document States
  COMPLETE: 'Completo',
  INCOMPLETE: 'En proceso de firma',
  ERROR: 'Error',
  BLOCKED: 'Bloqueado',
  REJECTED: 'Rechazado',
  ARCHIVED: 'Archivado',
  REJECTED_BY_SIGNER: 'Rechazado por firmante',
  REJECTED_BY_APPROVER: 'Rechazado por aprobador',
  EXPIRED: 'Caducado',

  // PromissoryNotes Document States
  COMPLETE$CREADO: 'Firmado',
  COMPLETE$PROCESO_EJECUCION: 'En proceso de ejecución',
  COMPLETE$EJECUCION: 'Ejecutado',
  COMPLETE$PROCESO_ELIMINACION: 'En proceso de cancelación',
  COMPLETE$ELIMINACION: 'Cancelado',
  COMPLETE$PROCESO_ENDOSO: 'En proceso de endoso',
  COMPLETE$ENDOSO: 'Endosado',

  // EndorsingDocument States
  ACCEPTED: 'Aceptado',
  WAIT_FOR_APPROVE: 'Esperando aprobación',

  // EndorsingDocument Columns
  receiverUuid: 'Cuenta receptora',
  newOwnerEmail: 'Email del receptor',
  responsibleEmail: 'Email del solicitante',

  //AuditLogs
  user: 'Usuario',
  operation: 'Operación',
  eventDate: 'Fecha',

  //Properties
  categories: 'Categoría',

  // Email states
  SENT: 'Enviado',
  DELIVERED: 'Entregado',
  BOUNCED: 'Rebotado',
  COMPLAINT: 'Reclamo',
  OTTO: 'Fuera de la oficina',
  OPEN: 'Abierto',
  CLICK: 'Click',

  // Profile Certificate
  'Account Electronic': 'Jurídica electrónica',
  'Account Digital': 'Jurídica digital',
  'Internal Electronic': 'Interna electrónica',
  'Internal Digital': 'Interna digital',
  'External Electronic': 'Externa electrónica',
  'External Digital': 'Externa digital',

  // Certificate profile filters
  type: 'Tipo',
  ACCOUNT: 'Cuenta',
  INTERNAL: 'Interno',
  EXTERNAL: 'Externo',

  // Properties filters
  value: 'Valor',
  encrypted: '¿Cifrada?',

  // Notification Logic filters
  periodicity: 'Periodicidad',

  // Notification Logic periodicity
  WEEKLY: 'Semanal',
  DAILY: 'Diaria',
  MONTHLY: 'Mensual',
  BIWEEKLY: 'Bisemanal',

  // Look and feel login types
  USER_PASSWORD: 'Usuario y contraseña',
  MICROSOFT_OAUTH_2_0: 'Microsoft OAuth 2.0',
  MICROSOFT_USER_PASSWORD: 'Microsoft usuario y contraseña',
  LEGACY_AD: 'AD Legacy',
};
