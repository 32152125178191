import { Role } from 'app/core/account';

export const roles: Role[] = [
  {
    id: 'b81131a3-38d0-40f9-a978-424b5f6926bc',
    value: 'ADMINISTRADOR',
    label: 'Administrador',
    command: '/entities',
    icon: 'manage_accounts',
  },
  {
    id: '7627e7fc-7d44-4e84-bb54-9b09c84e4471',
    value: 'FIRMANTE',
    label: 'Firmante',
    command: '/my-workflows',
    icon: 'history_edu',
  },
  {
    id: '5126e316-6a30-4f97-a17d-e6aaa476f468',
    value: 'PROPIETARIO',
    label: 'Propietario',
    command: '/workflows',
    icon: 'person',
  },
  {
    id: '5126e316-6a30-4f97-a17d-e6aaa476f468',
    value: 'REPORTEADOR',
    label: 'Reporteador',
    command: '/reports',
    icon: 'description',
  },
  {
    id: '5126e316-6a30-4f97-a17d-e6aaa476f468',
    value: 'AUDITOR',
    label: 'Auditor',
    command: '/audit-logs',
    icon: 'security',
  },
  {
    id: '5126e316-6a30-4f97-a17d-e6aaa476f468',
    value: 'SUPERADMINISTRADOR',
    label: 'Super Administrador',
    command: '/properties',
    icon: 'perm_contact_calendar',
  }
];
