import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { Result } from 'app/core/models';
import { Notification } from 'app/layout/common/notifications/notifications.types';
import { NotificationsService } from '../notifications.service';

@Component({
  selector: 'notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationDetailsComponent implements OnInit {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  html$: Observable<string>;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {  notification: Result<Notification> },
    private _dialogRef: MatDialogRef<NotificationDetailsComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.html$ = this._notificationsService.getHtml(this.data.notification.element.details);
  }
}
