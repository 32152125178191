import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AuthService } from './auth.service';
import { AuthInterceptor } from './auth.interceptor';
import { DEFAULT_TIMEOUT } from './auth.constants';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 30000 }
  ],
})
export class AuthModule {}
