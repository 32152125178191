import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { KeyValue } from '@angular/common';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { fuseAnimations } from '@fuse/animations';

import { Subject } from 'rxjs';
import { FiltersBarService } from './filters-bar.service';
import { TranslatorService } from 'app/core/translator';
import moment from 'moment';

@Component({
  selector: 'filters-bar',
  templateUrl: './filters-bar.component.html',
  styleUrls: ['./filters-bar.component.scss'],
  animations: fuseAnimations,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'filters-bar',
})
export class FiltersBarComponent implements OnInit, OnDestroy {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  @Input() filtersForm: FormGroup;
  @Input() dark: boolean = true;
  @Output() filters = new EventEmitter<void>();

  @ViewChild('filtersPanelOrigin') private _filtersOrigin: MatButton;
  @ViewChild('filtersPanel') private _filtersPanel: TemplateRef<any>;

  values = {};
  isExpanded: boolean = true;

  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    public translator: TranslatorService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _filtersBarService: FiltersBarService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    // Initialize the observable
    this._filtersBarService.filters = null;

    // Get the filters
    this._filtersBarService.filters$
      .subscribe((values) => {
      // Store the values
      this.values = values;

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the messages panel
   */
  openPanel(): void {
    // Return if the messages panel or its origin is not defined
    if (!this._filtersPanel || !this._filtersOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(
      new TemplatePortal(this._filtersPanel, this._viewContainerRef)
    );

    this.isExpanded = !this.isExpanded;
  }

  /**
   * Close the messages panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Check if the item is a Date
   */
  isDateType(val): boolean {
    return val instanceof Date;
  }

  /**
   * Check if the item is an Array
   */
  isArrayType(val): boolean {
    return val instanceof Array;
  }

  /**
   * Check if the item is a boolean
   */
  isBooleanType(val): boolean {
    return typeof val === 'boolean';
  }

  /**
   * Check if the item is a dictionary / object
   */
  isObjectType(val): boolean {
    return typeof val === 'object';
  }

  /**
   * Remove item from filters 'Set value to null'
   */
  removeItem(key: string): void {
    if (this.isObjectType(this.values[key]) && !this.isDateType(this.values[key])) {
      for (const [keyObject] of Object.entries(this.values[key])) {
        this.values[key][keyObject] = null;
      }
    } else {
      this.values[key] = null;
    }
    this._handleDataFlow();
  }

  /**
   * Remove subItem from item values array
   */
  removeSubItem(key: string, subItem: any): void {
    const index = this.values[key].indexOf(subItem);
    if (index > -1) {
      this.values[key].splice(index, 1);
    }
    this._handleDataFlow();
  }

  /**
   * check if object item is null
   * If all the features of the object are null the object is null
   */
  isNotNull(val: any): boolean {
    if (val) {
      if (this.isObjectType(val) && !this.isDateType(val)) {
        return !(Object.values(val).every(
          (x) => x === null || x === '' || x === undefined
        ));
      }
      return true;
    }
    return false;
  }

  /** Adjust end dates to include it*/
  setEndDate(): void {
    const values = this.filtersForm.getRawValue();
    Object.keys(values).forEach((key) => {
      if (this.isObjectType(values[key])) {
        if (values[key] && values[key]['end']) {
          this.filtersForm.get(key).setValue(
            {
              start: values[key]['start'],
              end: moment(values[key]['end']).endOf('day').toISOString(),
            }
          );
        }
      }
    });
  }

  /**
   * Convert value into Date
   */
  toDate(value: any): Date {
    return value as Date;
  }

  /** Check if value can be converted to date */
  canBeDate(value: any): boolean {
    return !isNaN(Date.parse(value));
  }

  /** Order by descending property key */
  order(a: KeyValue<string, string>, b: KeyValue<string, string>): number  {
    return b.key.localeCompare(a.key);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      maxHeight: '60%',
      hasBackdrop: true,
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(this._filtersOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          // top-left
          {
            originX: 'start', // left corner of the button
            originY: 'bottom', // bottom corner of the button
            overlayX: 'start', // left corner of the overlay to the origin
            overlayY: 'top', // top corner of the overlay to the origin
          },
          // top-right
          {
            originX: 'start', // left corner of the button
            originY: 'top', // top corner of the button
            overlayX: 'start', // left corner of the overlay to the origin
            overlayY: 'bottom', // top corner of the overlay to the origin
          },
          // bottom-left
          {
            originX: 'end', // right corner of the button
            originY: 'bottom', // bottom corner of the button
            overlayX: 'end', // right corner of the overlay to the origin
            overlayY: 'top', // top corner of the overlay to the origin
          },
          // bottom-right
          {
            originX: 'end', // right corner of the button
            originY: 'top', // top corner of the button
            overlayX: 'end', // right corner of the overlay to the origin
            overlayY: 'bottom', // top corner of the overlay to the origin
          },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  /**
   * Handle data flow
   */
  private _handleDataFlow(): void {
    // Set new element for observable
    this._filtersBarService.filters = this.values;

    // Set current values into the filter's form
    this.filtersForm.patchValue(this.values);

    // Emit submit event
    this.filters.emit();
  }
}
