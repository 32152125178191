// eslint-disable-next-line @typescript-eslint/naming-convention
import { MsalGuardConfiguration } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';

// eslint-disable-next-line @typescript-eslint/naming-convention
export const MSALGuardConfigFactory = (): MsalGuardConfiguration => ({
  interactionType: InteractionType.Redirect,
  authRequest: {
    scopes: ['user.read']
  },
  loginFailedRoute: '/login-failed-route'
});
