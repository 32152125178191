export enum ComparisonOperator {
  BEGINS_WITH = 'BEGINS_WITH',
  BETWEEN = 'BETWEEN',
  CONTAINS = 'CONTAINS',
  EQ = 'EQ',
  GE = 'GE',
  GT = 'GT',
  IN = 'IN',
  LE = 'LE',
  LT = 'LT',
  NE = 'NE',
  NOT_CONTAINS = 'NOT_CONTAINS',
  NOT_NULL = 'NOT_NULL',
  NULL = 'NULL',
}
