<div class="notification-details">

  <button class="notification-details-close-button" mat-icon-button [matDialogClose]="null">
    <mat-icon svgIcon="close" class="text-white"></mat-icon>
  </button>
  <!-- Content -->
  <ng-container *ngIf="data.notification.element.details">
    <ng-container *ngIf="html$ | async as html">
      <div [innerHtml]="html | safe: 'html'"></div>
    </ng-container>
  </ng-container>
</div>
