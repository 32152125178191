export interface Notification {
  accountUUid?: string;
  logicAlias?: string;
  details?: string;
  generationDate: Date;
  message?: string;
  status?: boolean;
  icon?: string;
  expirationDate?: Date;
  readingDate?: Date;
}

export enum NotificationMode {
  read = 'READ',
  toRead = 'TO_READ',
}
