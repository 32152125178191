/* eslint-disable @typescript-eslint/naming-convention */
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Amplify } from 'aws-amplify';
import { BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { MSAL_INSTANCE } from '@azure/msal-angular';

import { environment } from 'environments/environment';
import { AppModule } from 'app/app.module';

if (environment.production) {
  enableProdMode();
}

Amplify.configure({ Auth: environment.auth });

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

let domain = document.location.hostname;
domain = domain.split('.')[0];

fetch(`${environment.api.account}/getLookAndFeelCustomization?` + new URLSearchParams({
    domain,
  }))
  .then((response) => response.json())
  .then((json) => {
    platformBrowserDynamic([
      {
        provide: MSAL_INSTANCE,
        useFactory: (): IPublicClientApplication => new PublicClientApplication({
          auth: {
            clientId: json?.login?.clientId,
            authority: json?.login?.authority,
            redirectUri: '/sign-in',
            postLogoutRedirectUri: '/sign-out',
            navigateToLoginRequestUrl: false,
          },
          cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage,
            storeAuthStateInCookie: isIE,
          },
          system: {
            loggerOptions: {
              logLevel: LogLevel.Info,
              piiLoggingEnabled: false,
            },
          },
        }),
      },
    ]).bootstrapModule(AppModule)
      .catch((error) => console.error(error));
  });
