import { Component, OnInit } from '@angular/core';
import { filter, tap } from 'rxjs/operators';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { combineLatest } from 'rxjs';

import { AuthService } from 'app/core/auth/auth.service';
import { LookAndFeelService } from 'app/modules/look-and-feel/look-and-feel.service';
import { LoginType, LookAndFeel } from 'app/modules/look-and-feel/look-and-feel.types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _authService: AuthService,
    private _lookAndFeelService: LookAndFeelService,
    private _msalService: MsalService,
    private _msalBroadcastService: MsalBroadcastService,
    private _router: Router,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this._lookAndFeelService.lookAndFeel$
      .pipe(
        tap((lookAndFeel) => {
          if (lookAndFeel?.login?.type === LoginType.MICROSOFT_OAUTH_2_0) {
            this._listenMicrosoftLoginEvents();
          }
        }),
      ).subscribe();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------


  private _listenMicrosoftLoginEvents(): void {
    this._msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS || msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this._msalService.instance.setActiveAccount(payload.account);
        this._authService.microsoftUserLogin(payload.accessToken)
          .subscribe((response) => {
            // Navigate to the redirect url
            this._router.navigateByUrl('/signed-in-redirect');
          });
      });

    combineLatest([
      this._msalBroadcastService.inProgress$,
      this._lookAndFeelService.lookAndFeel$
    ])
      .pipe(filter(([status, lookAndFeel]) => status === InteractionStatus.None),)
      .subscribe(([status, lookAndFeel]) => {
        this._checkAndSetActiveAccount(lookAndFeel);
      });
  }

  private _checkAndSetActiveAccount(lookAndFeel: LookAndFeel): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
     */
    const activeAccount = this._msalService.instance.getActiveAccount();

    if (!activeAccount && this._msalService.instance.getAllAccounts().length > 0) {
      const accounts = this._msalService.instance.getAllAccounts();
      const account = accounts.find((acc) => lookAndFeel.login?.authority.includes(acc.tenantId));
      if (account) {
        this._msalService.instance.setActiveAccount(accounts[0]);
      }
    }
  }
}
