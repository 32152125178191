export const documents = [
  {
    uuid: '5126e316-6a30-4f97-a17d-e6aaa476f468',
    accountUuid: 'b7a13489-2e1f-4d84-ac29-9ba407072139',
    creationDate: '2021-06-24T22:53:56.386Z',
    s3OriginalPath:
      'https://s3.amazonaws.com/elogic-ra-qa-files/elogic-ra/' +
      '860007322-9/certificates/c0f9ccbd-62dd-45d4-accf-3869534b4541/' +
      'sign-operations/1628951798290/' +
      'Firmado-1628952093075-Anexo Tecnico Documento Soporte de Pago de Nomina Electronica - V1.0.pdf',
    s3SignedPath:
      'https://s3.amazonaws.com/elogic-ra-qa-files/elogic-ra/' +
      '860007322-9/certificates/c0f9ccbd-62dd-45d4-accf-3869534b4541/' +
      'sign-operations/1628951798290/' +
      'Firmado-1628952093075-Anexo Tecnico Documento Soporte de Pago de Nomina Electronica - V1.0.pdf',
    state: 'Incomplete',
    withTsa: false,
    signers: [
      {
        documentUuid: '5126e316-6a30-4f97-a17d-e6aaa476f468',
        order: 1,
        state: 'Signed',
        signDate: '2021-06-24T22:53:56.386Z',
        signer: {
          uuid: '01d92de8-3f87-4178-809a-1ff6d22db832',
          accountUuid: '0b696e14-263b-44da-a7d8-3d3b5c9b28d4',
          idType: 'CC',
          id: '1234567890',
          names: 'David',
          lastNames: 'Tafur',
          email: 'david.tafur@software-colombia.com',
        },
      },
      {
        documentUuid: '5126e316-6a30-4f97-a17d-e6aaa476f468',
        order: 2,
        state: 'WaitForSign',
        signer: {
          uuid: '088cfcb0-4125-41d5-b0a6-16697a5694d7',
          accountUuid: '0b696e14-263b-44da-a7d8-3d3b5c9b28d4',
          idType: 'CC',
          id: '1234567890',
          names: 'Sebastian',
          lastNames: 'Vargas',
          email: 'juan.vargas@software-colombia.com',
        },
      },
      {
        documentUuid: '5126e316-6a30-4f97-a17d-e6aaa476f468',
        order: 3,
        state: 'WaitForSign',
        signer: {
          uuid: '03279bc9-a97f-4f98-aa16-537396bead53',
          accountUuid: '0b696e14-263b-44da-a7d8-3d3b5c9b28d4',
          idType: 'CC',
          id: '1234567890',
          names: 'Bryan',
          lastNames: 'Guzman',
          email: 'bryan.guzman@software-colombia.com',
        },
      },
    ],
  },
];
