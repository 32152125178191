import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, of, ReplaySubject} from 'rxjs';
import { User } from 'app/modules/users/users.types';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _user: ReplaySubject<any> = new ReplaySubject<any>(1);
  private _userDetails: BehaviorSubject<User> = new BehaviorSubject(null);

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter for user
   */
  set user(value: any) {
    // Store the user
    localStorage.setItem('WORKFLOW_USER', JSON.stringify(value));

    // Update the user
    this._user.next(value);
  }

  /**
   * Getter for user
   */
  get user$(): Observable<any> {
    return this._user.asObservable();
  }

  /** Setter user details */
  set userDetails(value: User) {
    this._userDetails.next(value);
  }

  /* Getter user details */
  get $userDetails(): Observable<User> {
    return this._userDetails.asObservable();
  }


  // -------------------------------------------------------------------------------
  // @ Public methods
  // -------------------------------------------------------------------------------

  /**
   * Get the user data
   */
  get(): Observable<any> {
    // Get the user from the storage
    const user = JSON.parse(localStorage.getItem('WORKFLOW_USER'));

    // Update the user
    this._user.next(user);

    // Return the user
    return of(user);
  }

  /**
   * Clear the user data
   */
  clear(): void {
    // Remove the user from the storage
    localStorage.removeItem('WORKFLOW_USER');

    // Update the user
    this._user.next(null);
  }
}
