import { Injectable } from '@angular/core';

import { dictionary } from './dictionary';

@Injectable({
  providedIn: 'root',
})
export class TranslatorService {

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public translate(key: string): string {
    return dictionary[key] || key;
  }
}
