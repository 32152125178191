import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable, of, ReplaySubject } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { FuseSplashScreenService } from '@fuse/services/splash-screen';

import { Account, Role } from './account.types';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _roles: ReplaySubject<Role[]> = new ReplaySubject(1);
  private _account: ReplaySubject<Account> = new ReplaySubject(1);

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _router: Router,
    private _httpClient: HttpClient,
    private _splashService: FuseSplashScreenService,
  ) {
    // Initialize data
    this._roles.next(null);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter for account
   */
  set account(value: any) {
    // Store the account
    localStorage.setItem('WORKFLOW_ACCOUNT', JSON.stringify(value));

    // Update the account
    this._account.next(value);
  }

  /**
   * Getter for roles
   */
  get roles$(): Observable<Role[]> {
    return this._roles.asObservable();
  }

  /**
   * Getter for account
   */
  get account$(): Observable<Account> {
    return this._account.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the account data
   */
  get(): Observable<Account> {
    // Get the account from the storage
    const account = JSON.parse(localStorage.getItem('WORKFLOW_ACCOUNT'));

    // Update the account
    this._account.next(account);

    // Return the account
    return of(account);
  }

  /**
   * Get the application roles
   */
  roles(): Observable<Role[]> {
    // Get the roles
    return this.roles$.pipe(
      take(1),
      switchMap((roles) => {
        // If roles exists...
        if (roles) {
          // Return the roles
          return of(roles);
        }

        // Show splash screen
        this._splashService.show();

        // Get the roles from server
        return this._httpClient.get<Role[]>('api/common/roles').pipe(
          tap((response) => {
            // Update the roles
            this._roles.next(response);

            // Hide splash screen
            this._splashService.hide();
          })
        );
      })
    );
  }

  /**
   * Navigate to the default route
   */
  navigate(role?: string): void {
    // If no role is provided...
    if (!role) {
      // Get the account from the storage
      const account = JSON.parse(localStorage.getItem('WORKFLOW_ACCOUNT'));

      // Update the role
      role = account.selectedRole;
    }

    // Get the application roles
    this.roles().subscribe((roles) => {
      // Find the role
      const found = roles.find((item) => item.value === role);

      // Navigate to the default route
      this._router.navigate([found.command]);
    });
  }

  /**
   * Clear the account
   */
  clear(): void {
    // Remove the account from the storage
    localStorage.removeItem('WORKFLOW_ACCOUNT');

    // Update the account
    this._account.next(null);
  }
}
