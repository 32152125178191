import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuthService } from 'app/core/auth';
import { AccountService } from 'app/core/account';
import {Module, NavigationService} from 'app/core/navigation';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate, CanActivateChild, CanLoad {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _accountService: AccountService,
    private _navigation: NavigationService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check(route);
  }

  /**
   * Can activate child
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._check(childRoute);
  }

  /**
   * Can load
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check(route);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Check the role status
   *
   * @private
   */
  private _check(route: Route | ActivatedRouteSnapshot): Observable<boolean> {
    // Check the role status
    return this._authService.checkRoleStatus().pipe(
      switchMap((roleSelected) => {
        // If the user has not selected a role...
        if (!roleSelected) {
          // Navigate to select entity
          this._router.navigate(['select-entity']);

          // Prevent the access
          return of(false);
        }

        // Get the available modules
        return this._navigation.get().pipe(
          switchMap((modules) => {
            // If the requested module is not in the available modules...
            if (!RoleGuard._findModule(modules, route.data.id)) {
              // Redirect to the default page
              this._accountService.navigate();

              // Prevent the access
              return of(false);
            }

            // Allow the access
            return of(true);
          })
        );
      })
    );
  }

  /**
   * Find module recursively
   *
   * @private
   */
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private static _findModule(modules: Module[], id: string): Module {
    // Prepare the module
    let found;

    // Iterate trough the modules...
    for (const module of modules) {
      // If module ID match...
      if (module.id === id) {
        // Store the module
        found = module;

        // Return the module
        return found;
      }

      // If module has children...
      if (module.children && module.children.length) {
        // Find module in children
        found = this._findModule(module.children, id);

        // If module is found in children...
        if (found) {
          // Return the module
          return found;
        }
      }
    }
  }
}
