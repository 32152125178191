import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'paginator',
})
export class PaginatorComponent implements OnInit {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  @Input() hasNext: boolean;
  @Input() hasBefore: boolean;
  @Input() pageSize: number;
  @Input() pageSizeOptions: number[];

  @Output() page: EventEmitter<any> = new EventEmitter<any>();

  public next: any;
  public before: any;

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor() {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Set the default has next
    this.hasNext = this.hasNext !== null ? this.hasNext : false;

    // Set the default has before
    this.hasBefore = this.hasBefore !== null ? this.hasBefore : false;

    // Set the default page size
    this.pageSize = this.pageSize ?? 5;

    // Set the default page size options
    this.pageSizeOptions = this.pageSizeOptions ?? [5, 10, 25, 50, 100];
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On page change
   */
  onPageChange(next: any, before: any): void {
    this.next = next;
    this.before = before;
    this.page.emit({ total: this.pageSize, next: next, before: before });
  }

  /**
   * Track by function for ngFor loops
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }
}


