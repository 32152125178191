import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from 'app/core/snackbar';
import { finalize } from 'rxjs/operators';

import { EBiometricsTransactionsService } from 'app/shared/e-biometrics-transactions/e-biometrics-transactions.service';

@Component({
  selector: 'e-biometrics-transactions',
  templateUrl: './e-biometrics-transactions.component.html',
  styleUrls: ['./e-biometrics-transactions.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EBiometricsTransactionsComponent implements OnInit {

  // ------------------------------------------------------------------------------
  // @ Attributes
  // ------------------------------------------------------------------------------

  isLoading: boolean = false;

  // ------------------------------------------------------------------------------
  // @ Constructor
  // ------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<EBiometricsTransactionsComponent>,
    private _changeDetectorRef: ChangeDetectorRef,
    private _snackbar: SnackbarService,
    private _eBiometricsTransactionsService: EBiometricsTransactionsService,
  ) { }

  // ------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // ------------------------------------------------------------------------------

  ngOnInit(): void {}

  // ------------------------------------------------------------------------------
  // @ Public methods
  // ------------------------------------------------------------------------------

  downloadReport(id: string): void {
    this.isLoading = true;
    this._eBiometricsTransactionsService.downloadReport(id, this.data.documentUuid, this.data.signer.order + 1)
      .pipe(finalize(() => {
        this.isLoading = false;
        this._changeDetectorRef.markForCheck();
      }))
      .subscribe({
        next: (url) => {
          window.open(url, '_blank');
        },
        error: () => {
          this._snackbar.showError('Ocurrió un error descargando el reporte. Por favor inténtelo de nuevo')
        }
      })
  }
}
