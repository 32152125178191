<div class="paginator">

  <!-- Items per page -->
  <span class="paginator-page-size-label">Elementos por página:</span>
  <mat-form-field class="paginator-page-size-select">
    <mat-select [(value)]="pageSize" (selectionChange)="onPageChange(null, null)">
      <ng-container *ngFor="let size of pageSizeOptions; trackBy: trackByFn">
        <mat-option [value]="size">{{ size }}</mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>

  <!-- Previous page -->
  <button
    mat-mini-fab
    color="primary"
    class="paginator-navigation-previous"
    matTooltip="Página anterior"
    [disabled]="!hasBefore"
    (click)="onPageChange(null, true)"
  >
    <mat-icon svgIcon="chevron_left"></mat-icon>
  </button>

  <!-- Next page -->
  <button
    mat-mini-fab
    color="primary"
    class="paginator-navigation-next"
    matTooltip="Página siguiente"
    [disabled]="!hasNext"
    (click)="onPageChange(true, null)"
  >
    <mat-icon svgIcon="chevron_right"></mat-icon>
  </button>
</div>
