<div class="flex flex-col max-w-240 md:min-w-160 max-h-screen -m-6">

  <!-- Main -->
  <div class="flex flex-col flex-auto p-6 sm:p-8 overflow-y-auto">

    <!-- Body -->
    <div class="flex flex-col gap-8 justify-center items-center">

      <ng-container *ngIf="!OTP">
        <mat-progress-spinner color="primary" mode="indeterminate" [diameter]="80"></mat-progress-spinner>
      </ng-container>

      <ng-container *ngIf="OTP">
        <!-- Animation -->
        <div class="flex flex-0 items-center justify-center w-44 h-44 rounded-full bg-primary-100 dark:bg-primary-500">
          <ng-lottie class="mt-5" [options]="options"></ng-lottie>
        </div>

        <p class="text-secondary text-center">Ingrese el código que recibió al número de celular y/o correo electrónico registrado en la plataforma.</p>

        <!-- OTP Input -->
        <ng-otp-input
          #OTPInput
          (onInputChange)="onOtpChange($event)"
          [config]="{length: 4, allowNumbersOnly: true, disableAutoFocus: false, inputClass: 'text-secondary focus:border-primary w-12 h-12' }">
        </ng-otp-input>

        <!-- Alert -->
        <fuse-alert
          *ngIf="showAlert"
          class="-mt-5"
          appearance="outline"
          [showIcon]="true"
          [type]="alert.type"
          [@shake]="alert.type === 'error'"
        >
          {{ alert.message }}
        </fuse-alert>

        <!-- Reset -->
        <p class="text-secondary">
          ¿No recibió el código?
          <ng-container *ngIf="counter < 1; else countDown">
            <u class="text-primary cursor-pointer" (click)="getOTP(); showAlert = false; OTPInput.setValue(null)">Reenviar</u>
          </ng-container>
        </p>
      </ng-container>

      <ng-container *ngIf="!data">
        Un error wacho
      </ng-container>

    </div>
  </div>
</div>

<ng-template #countDown>
  <div class="p-3 bg-gray-500 bg-opacity-10 rounded flex justify-center mt-3">
    Reenviar en: {{ counter }}
  </div>
</ng-template>
