import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { FuseFullscreenModule } from '@fuse/components/fullscreen';
import { FuseNavigationModule } from '@fuse/components/navigation';

import { SharedModule } from 'app/shared/shared.module';
import { LanguagesModule, UserModule, SettingsModule, NotificationsModule } from 'app/layout/common';
import { ClassicLayoutComponent } from './classic.component';

@NgModule({
  declarations: [ClassicLayoutComponent],
    imports: [
      HttpClientModule,
      RouterModule,
      SharedModule,
      MatButtonModule,
      MatDividerModule,
      MatIconModule,
      MatMenuModule,
      FuseFullscreenModule,
      FuseNavigationModule,
      LanguagesModule,
      UserModule,
      SettingsModule,
      NotificationsModule,
    ],
  exports: [ClassicLayoutComponent],
})
export class ClassicLayoutModule {}
