import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { FuseNavigationService, FuseVerticalNavigationComponent } from '@fuse/components/navigation';

import { NavigationService } from 'app/core/navigation';
import { LookAndFeelService } from 'app/modules/look-and-feel/look-and-feel.service';
import { LookAndFeel } from 'app/modules/look-and-feel/look-and-feel.types';
import { PROJECT_VERSION, ProjectVersion } from 'app/core/version';
import { Account, AccountService } from 'app/core/account';
import { roles } from 'app/modules/users/users.constants';

@Component({
  selector: 'classic-layout',
  templateUrl: './classic.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ClassicLayoutComponent implements OnInit, OnDestroy {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  isScreenSmall: boolean;
  navigation: any[];
  lookAndFeel: LookAndFeel;

  roles = roles;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _fuseNavigationService: FuseNavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _navigationService: NavigationService,
    private _lookAndFeelService: LookAndFeelService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  /**
   * Getter for current version
   */
  get currentVersion(): ProjectVersion {
    return PROJECT_VERSION;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Get the navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation) => {
        // Store the navigation
        this.navigation = navigation;
      });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });

    // Get the look and feel
    this._lookAndFeelService.lookAndFeel$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((lookAndFeel) => {
        // Store the look and feel
        this.lookAndFeel = lookAndFeel;
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
}
