<!-- --------------------------------------------------------------------- -->
<!-- Empty layout -->
<!-- --------------------------------------------------------------------- -->

<empty-layout *ngIf="layout === 'empty'"></empty-layout>

<!-- --------------------------------------------------------------------- -->
<!-- Layouts with vertical navigation -->
<!-- --------------------------------------------------------------------- -->

<!-- Classic -->
<classic-layout *ngIf="layout === 'classic'"></classic-layout>
