import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { MatDialog } from '@angular/material/dialog';
import { MatButton } from '@angular/material/button';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { NotificationsService } from './notifications.service';
import {Notification, NotificationMode} from './notifications.types';
import { Result } from 'app/core/models';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'notifications',
})
export class NotificationsComponent implements OnInit, OnDestroy {

  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  @ViewChild('notificationsOrigin') _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel') _notificationsPanel: TemplateRef<any>;

  notifications$: Observable<Result<Notification>[]>;

  isLoading: boolean = false;
  isModule: boolean = false;

  notificationType: NotificationMode = NotificationMode.toRead;
  notificationMode = NotificationMode;

  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _router: Router,
    private _overlay: Overlay,
    private _matDialog: MatDialog,
    private _viewContainerRef: ViewContainerRef,
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /** On init */
  ngOnInit(): void {
    this.onTypeChanged(this.notificationType);
    this.notifications$ = this._notificationsService.notifications$;
  }

  /** On destroy */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  readAll(): void {
    this._notificationsService.updateNotifications().subscribe();
  }

  read(notification: Result<Notification>): void {
    this._notificationsService.updateNotification(notification).subscribe();
  }

  /** On type changed */
  onTypeChanged(type: NotificationMode): void {
    if (type === this.notificationType) { return; }
    this.notificationType = type;
    this._notificationsService.getNotifications(type)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe();
    this._changeDetectorRef.markForCheck();
  }

  /** Open the notifications panel */
  openPanel(): void {
    // If path is notifications module
    if (this.isModule) { return; }
    // Return if the notifications panel or its origin is not defined
    if (!this._notificationsPanel || !this._notificationsOrigin) { return; }
    // Create the overlay if it doesn't exist
    if (!this._overlayRef) { this._createOverlay(); }
    // Attach the portal to the overlay
    this._overlayRef.attach( new TemplatePortal(this._notificationsPanel, this._viewContainerRef) );
  }

  /** Open notification's details */
  details(notification: Result<Notification>): void {
    if (!notification.element.details) { return; }
    this._matDialog.open(NotificationDetailsComponent, {
      panelClass: 'html-dialog-panel',
      data: { notification },
    });
  }

  /** Close the messages panel */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /** Track by function for ngFor loops */
  trackByFn(index: number, item: any): any {
    return item?.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /** Create the overlay */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay
        .position()
        .flexibleConnectedTo(
          this._notificationsOrigin._elementRef.nativeElement
        )
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          { originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top' },
          { originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom' },
          { originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top' },
          { originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom' },
        ]),
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }
}
