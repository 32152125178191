/* eslint-disable curly */
/* eslint-disable @typescript-eslint/naming-convention */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { AnimationOptions } from 'ngx-lottie';

import { OtpService } from './otp.service';
import { take, takeUntil } from 'rxjs/operators';
import sha256 from 'crypto-js/sha256';
import Base64 from 'crypto-js/enc-base64';

@Component({
  selector: 'otp',
  templateUrl: './otp.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: fuseAnimations,
})
export class OtpComponent implements OnInit, OnDestroy {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  TICK: number = 1000;
  COUNTDOWN: number = 46;
  ATTEMPTS: number = 3;

  currentAttempt: number = 0;
  OTP: string = null;
  counter: number;
  countDown: any;

  options: AnimationOptions = { path: '/assets/lottie/verifyCode.json' };

  showAlert: boolean = false;
  alert: any = { type: 'error', message: 'El código introducido no es correcto, intentos restantes: ' };

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<OtpComponent>,
    private _otpService: OtpService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    if (this.data) this.getOTP();
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  getOTP(): void {
    this.OTP = null;
    this._otpService.getOTP(this.data)
      .pipe(take(1), takeUntil(this._unsubscribeAll))
      .subscribe((OTP) => {
        if (OTP?.otp) {
          this.OTP = OTP.otp;
          this.startCounter();
        } else this.matDialogRef.close(false);
        this._changeDetectorRef.markForCheck();
      });
  }

  onOtpChange(event: any): void {
    this.showAlert = false;
    if (event.length === 4) {
      const hash = Base64.stringify(sha256(event));
      if (hash === this.OTP) this.matDialogRef.close(true);
      else {
        this.currentAttempt++;
        if (this.currentAttempt >= 3) this.matDialogRef.close(false);
        else {
          this.alert.message = `${this.alert.message.slice(0, -1)} ${(this.ATTEMPTS - this.currentAttempt)}`;
          this.showAlert = true;
        }
      }
    }
    this._changeDetectorRef.markForCheck();
  }

  startCounter(): void {
    this.counter = this.COUNTDOWN;
    this.countDown = this._otpService.getCounter(this.TICK)
      .subscribe(() => {
        this.counter--;
        this._changeDetectorRef.markForCheck();
      });
  }
}
