import { MatPaginatorIntl } from '@angular/material/paginator';

const esRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) { return `0 de ${length}`; }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;

  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex = startIndex < length ?
    Math.min(startIndex + pageSize, length) :
    startIndex + pageSize;

  return `${startIndex + 1} - ${endIndex} de ${length}`;
}

const customPaginatorIntl = new MatPaginatorIntl();
customPaginatorIntl.itemsPerPageLabel = 'Elementos por página:';
customPaginatorIntl.firstPageLabel = 'Primera página';
customPaginatorIntl.lastPageLabel = 'Última página';
customPaginatorIntl.nextPageLabel = 'Siguiente página';
customPaginatorIntl.previousPageLabel = 'Anterior página';
customPaginatorIntl.getRangeLabel = esRangeLabel;

export { customPaginatorIntl };
