import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { _MatMenuDirectivesModule, MatMenuModule } from '@angular/material/menu';

import { SharedModule } from 'app/shared/shared.module';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { NotificationDetailsComponent } from './notification-details/notification-details.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDividerModule } from '@angular/material/divider';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [
    NotificationsComponent,
    NotificationDetailsComponent,
  ],
  imports: [
    RouterModule,
    OverlayModule,
    PortalModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    SharedModule,
    MatButtonToggleModule,
    MatProgressBarModule,
    MatDividerModule,
    MatRippleModule,
    MatMenuModule,
    MatDialogModule,
    _MatMenuDirectivesModule,
  ],
  exports: [NotificationsComponent],
})
export class NotificationsModule {}
