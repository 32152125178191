import { Injectable } from '@angular/core';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { TIMEOUT_COUNT } from 'app/core/auth/auth.constants';
import { SnackbarService } from 'app/core/snackbar';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class HClientService {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _snackBar: SnackbarService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  check(): Observable<boolean> {
    const options = { context: new HttpContext().set(TIMEOUT_COUNT, 1000) };
    return this._httpClient.get(`${environment.api.hClient}/ping`, options).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  encrypt(signer: any, account: string, document: string): Observable<string> {
    const request = {
      params: {
        accountId: account,
        cedula: signer.id,
        document: document,
        enc: 'false',
        // tipo: "",
        // desc: "",
      },
    };

    return this._httpClient
      .get<any>(`${environment.api.flow}/certihuella/encrypt`, request)
      .pipe(
        catchError((error) => {
          this._snackBar.showError(error.error.message);
          return of(error);
        })
      );
  }

  validate(body: any): Observable<any> {
    const options = { context: new HttpContext().set(TIMEOUT_COUNT, 1800000) };
    return this._httpClient.post<any>(`${environment.api.hClient}`, body, options).pipe(
      map((response) => JSON.parse(response.parameters))
    );
  }
}
