import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable()
export class SnackbarService {
  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(private _snackBar: MatSnackBar) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show a success snackbar
   */
  showSuccess(
    message: string,
    duration: number = 7000,
    action: string = null,
    horizontal: MatSnackBarHorizontalPosition = 'center',
    vertical: MatSnackBarVerticalPosition = 'bottom'
  ): void {
    this._snackBar.open(message, action, {
      horizontalPosition: horizontal,
      verticalPosition: vertical,
      duration: duration,
      panelClass: ['success-snackbar'],
    });
  }

  /**
   * Show an info snackbar
   */
  showInfo(
    message: string,
    duration: number = 7000,
    action: string = null,
    horizontal: MatSnackBarHorizontalPosition = 'center',
    vertical: MatSnackBarVerticalPosition = 'bottom'
  ): void {
    this._snackBar.open(message, action, {
      horizontalPosition: horizontal,
      verticalPosition: vertical,
      duration: duration,
      panelClass: ['info-snackbar'],
    });
  }

  /**
   * Show an error snackbar
   */
  showError(
    message: string,
    duration: number = 7000,
    action: string = null,
    horizontal: MatSnackBarHorizontalPosition = 'center',
    vertical: MatSnackBarVerticalPosition = 'bottom'
  ): void {
    this._snackBar.open(message, action, {
      horizontalPosition: horizontal,
      verticalPosition: vertical,
      duration: duration,
      panelClass: ['error-snackbar'],
    });
  }
}
