<!-- Toggle button -->
<button
  mat-icon-button
  [matTooltip]="tooltip || 'Configuración de apariencia'"
  (click)="openPanel()"
  #configOrigin
>
  <mat-icon svgIcon="settings"></mat-icon>
</button>

<!-- Config panel -->
<ng-template #configPanel>
  <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

    <!-- Header -->
    <div class="flex flex-shrink-0 items-center py-1 pr-4 pl-6 bg-primary text-on-primary">
      <div class="sm:hidden -ml-1 mr-3">
        <button mat-icon-button (click)="closePanel()">
          <mat-icon class="icon-size-5 text-current" svgIcon="close"></mat-icon>
        </button>
      </div>
      <div class="text-lg font-medium leading-10">Configuración</div>
    </div>

    <!-- Content -->
    <div class="relative flex flex-col flex-auto sm:max-h-120 overflow-y-auto bg-card p-6">

      <!-- Scheme -->
      <div class="text-md font-semibold text-secondary">Tema</div>
      <div class="grid grid-cols-3 gap-3 justify-items-center mt-4">

        <!-- Auto -->
        <div
          class="flex items-center justify-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.scheme === 'auto'"
          [matTooltip]="'Establece automáticamente el tema basado en el tema configurado del sistema operativo.'"
          (click)="setScheme('auto')"
        >
          <div class="flex items-center rounded-full overflow-hidden">
            <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:lightning-bolt'"></mat-icon>
          </div>
          <div class="ml-1.5 text-sm font-medium leading-5 truncate" [class.text-secondary]="config.scheme !== 'auto'">
            Auto
          </div>
        </div>

        <!-- Dark -->
        <div
          class="flex items-center justify-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.scheme === 'dark'"
          (click)="setScheme('dark')"
        >
          <div class="flex items-center rounded-full overflow-hidden">
            <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:moon'"></mat-icon>
          </div>
          <div class="ml-1.5 text-sm font-medium leading-5 truncate" [class.text-secondary]="config.scheme !== 'dark'">
            Oscuro
          </div>
        </div>

        <!-- Light -->
        <div
          class="flex items-center justify-center px-4 py-3 rounded-full cursor-pointer ring-inset ring-primary bg-hover"
          [class.ring-2]="config.scheme === 'light'"
          (click)="setScheme('light')"
        >
          <div class="flex items-center rounded-full overflow-hidden">
            <mat-icon class="icon-size-4" [svgIcon]="'heroicons_solid:sun'"></mat-icon>
          </div>
          <div class="ml-1.5 text-sm font-medium leading-5 truncate" [class.text-secondary]="config.scheme !== 'light'">
            Claro
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
