/* eslint-disable curly */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { FuseMockApiUtils } from '@fuse/lib/mock-api';

import { AccountService } from 'app/core/account';
import { SnackbarService } from 'app/core/snackbar';
import { Country, Pagination, Query, Response, Result } from 'app/core/models';
import { AuditLogsService } from 'app/modules/audit-logs/audit-logs.service';
import { AuditOperations } from 'app/modules/audit-logs/audit-logs.types';
import { Entity } from './entities.types';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntitiesService {
  // -----------------------------------------------------------------------------------------------------
  // @ Attributes
  // -----------------------------------------------------------------------------------------------------

  private _entity: BehaviorSubject<Entity> = new BehaviorSubject(null);
  private _entities: BehaviorSubject<Entity[]> = new BehaviorSubject(null);
  private _pagination: BehaviorSubject<Pagination> = new BehaviorSubject(null);
  private _countries: BehaviorSubject<Country[]> = new BehaviorSubject(null);

  // -----------------------------------------------------------------------------------------------------
  // @ Constructor
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private _httpClient: HttpClient,
    private _accountService: AccountService,
    private _snackbar: SnackbarService,
    private _auditLogsService: AuditLogsService,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  get entity$(): Observable<Entity> { return this._entity.asObservable(); }
  set entity(value: Entity) { this._entity.next(value); }
  get entities$(): Observable<Entity[]> { return this._entities.asObservable(); }
  set entities(value: Entity[]) { this._entities.next(value); }
  get pagination$(): Observable<Pagination> { return this._pagination.asObservable(); }
  set pagination(value: Pagination) { this._pagination.next(value); }
  get countries$(): Observable<Country[]> { return this._countries.asObservable(); }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Get the entities
   */
  getEntities(query: Query): Observable<Response<any>> {
    return this._accountService.account$.pipe(
      take(1),
      switchMap((account) => {
        query.keyValue = { parentAccountUuid: account.accountUuid };
        return this._httpClient
          .post<Response<any>>(`${environment.api.account}/accountService`, query, { params: { type: 'artificial' } })
          .pipe(
            tap((response) => {
              const entities = response.results.map((item) => {
                item.element.id = item.id;
                return item.element;
              });

              delete response.results;
              this._entities.next(entities);
              this._pagination.next({ ...response, size: query.total ?? 10 });
            })
          );
      })
    );
  }

  /**
   * Get entity by uuid
   */
  getEntity(uuid: string): Observable<Entity> {
    return this.entities$.pipe(
      take(1),
      map((entities) => {
        if (!entities) return null;
        const entity = entities.find((item) => item.uuid === uuid) || null;
        this._entity.next(entity);
        return entity;
      }),
      switchMap((entity) => {
        if (!entity) return this.getEntityByUuid(uuid);
        return of(entity);
      }),
      switchMap((entity) => {
        if (!entity) return throwError(`Could not found entity with uuid of ${uuid}!`);
        return of(entity);
      })
    );
  }

  /**
   * Get entity by uuid
   */
  getEntityByUuid(uuid: string): Observable<Entity> {
    return this.entity$.pipe(
      take(1),
      switchMap((entity) => {
        if (entity && entity.uuid === uuid) return of(entity);
        const query: Query = { total: 1, keyValue: { uuid } };

        return this._httpClient
          .post<Response<any>>(`${environment.api.account}/accountService`, query, { params: { type: 'list' } })
          .pipe(
            map((response) => {
              if (response.results[0]) {
                const found = response.results[0]?.element;
                found.id = response.results[0]?.id;
                this._entity.next(found);
                return found;
              }
              return null;
            })
          );
      })
    );
  }

  /**
   * Create entity
   */
  createEntity(entity: Entity): Observable<Entity> {
    return this._accountService.account$.pipe(
      take(1),
      switchMap((account) =>
        this.entities$.pipe(
          take(1),
          switchMap((entities) => {
            entity.uuid = FuseMockApiUtils.guid();
            entity.parentAccountUuid = account.accountUuid;
            return this._httpClient
              .post<{ id: string }>(`${environment.api.account}/accountService`, entity)
              .pipe(
                map((response) => {
                  this._auditLogsService.createAuditLog(AuditOperations.CREATED_ENTITY, { name: entity.company.name }).subscribe();
                  this._snackbar.showSuccess(`¡La entidad ${entity.company.name} ha sido creada exitosamente!`);
                  entity.id = response.id;
                  this._entities.next([entity, ...entities]);
                  return entity;
                })
              );
          })
        )
      )
    );
  }

  /**
   * Update entity
   */
  updateEntity(entity: Entity): Observable<Entity> {
    return this.entities$.pipe(
      take(1),
      switchMap((entities) =>
        this._httpClient
          .put<{ id: string }>(`${environment.api.account}/accountService/${entity.id}`, entity)
          .pipe(
            map((response) => {
              this._auditLogsService.createAuditLog(AuditOperations.EDITED_ENTITY, { name: entity.company.name }).subscribe();
              this._snackbar.showSuccess(`¡La entidad ${entity.company.name} ha sido actualizada exitosamente!`);
              entity.id = response.id;

              if (entities) {
                const index = entities.findIndex((item) => item.id === response.id);
                entities[index] = entity;
                this._entities.next(entities);
              }

              return entity;
            }),
            switchMap(() =>
              this.entity$.pipe(
                take(1),
                filter((item) => item && item.id === entity.id),
                tap(() => this._entity.next(entity))
              )
            )
          )
      )
    );
  }

  /**
   * Delete entity
   */
  deleteEntity(entity: Entity): Observable<boolean> {
    return this.entities$.pipe(
      take(1),
      switchMap((entities) =>
        this._httpClient.delete<any>(`${environment.api.account}/accountService/${entity.id}`)
          .pipe(
            map(() => {
              this._auditLogsService.createAuditLog(AuditOperations.DELETED_ENTITY, { name: entity.company.name }).subscribe();
              this._snackbar.showSuccess(`¡La entidad ${entity.company.name} ha sido eliminada!`);
              const index = entities.findIndex((item) => item.id === entity.id);
              entities.splice(index, 1);
              this._entities.next(entities);
              return true;
            })
          )
      )
    );
  }

  /**
   * Return a list of entities given a query element
   */
  getEntitiesByFilter(query: Query): Observable<Result<Entity>[]> {
    return this._httpClient
      .post<Response<Entity>>(`${environment.api.account}/accountService`, query, { params: { type: 'all' } })
      .pipe(map((response) => response.results));
  }

  /**
   * Get countries
   */
  getCountries(): Observable<Country[]> {
    return this._httpClient.get<Country[]>('api/countries').pipe(
      tap((countries) => this._countries.next(countries))
    );
  }
}
